/* eslint-disable */
import { useEffect, useMemo, useState } from "react";
import {
    useSetRecoilState,
    useRecoilValue,
    useRecoilState,
    atom,
} from "recoil";
import { history, useFetchWrapper, apiBaseURL } from "helpers";

export { useModalRoomType };
const baseApi = "useModalRoomType";
const dataAtom = atom({
    key: `${baseApi}Atom`,
    default: false,
});


function useModalRoomType() {

    const [isOpenModal, setOpenModal] = useRecoilState(dataAtom);


    const openModal = async (isOpen: boolean): Promise<any> => {
        console.log('openModal', isOpen)
        setOpenModal(isOpen);
    }



    return {
        isOpenModal, openModal
    };

}
