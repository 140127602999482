import React from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_HOTEL } from "data/navigation";

function NavigationHotel() {
    return (
        <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
            {NAVIGATION_HOTEL.map((item) => (
                <NavigationItem key={item.id} menuItem={item} />
            ))}
        </ul>
    );
}

export default NavigationHotel;
