import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import Select from "shared/Select/Select";

export interface PageCreditProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "888 Moo 7 Samnukkam, Sadao , Songkhla, Thailand 90320",
  },
  {
    title: "💌 EMAIL",
    desc: "nc.example@example.com",
  },
  {
    title: "☎ PHONE",
    desc: "+66 74 557172",
  },
];

const PageCredit: FC<PageCreditProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageCredit overflow-hidden ${className}`}
      data-nc-id="PageCredit"
    >
      {/* <Helmet>
        <title>Booking || M Hotel</title>
      </Helmet> */}
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Credit
        </h2>

        <div className=" container grid grid-cols-1 sm:grid-cols-2 ">
          <form className=" listingSection__wrap max-w ">
            <div className="font-semibold bg-blue-500 ">
              <h3 className="ml-3 text-white text-xl">บัตรเครดิต/บัตรเดบิต</h3>
            </div>
            <label className="block">
              <Label>เลือกวิธีชำระเงิน</Label>
              <Select className="mt-1.5">
                <option value="1">Visa / MasterCard / JCB / Amax</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Select>
            </label>
            <label className="rounded bg-lime-100 text-center text-sm font-semibold  ">
              <Label className="text-base font-semibold text-lime-700  ">
                กรอกข้อมูลเพื่อจองห้องพัก
              </Label>
            </label>
            <label className="block">
              <Label>ชื่อบนบัตร</Label>
              <Input type="text" className="mt-1" />
            </label>
            <label className="block">
              <Label>หมายเลขบัตรเครดิต/เดบิต</Label>
              <Input type="text" className="mt-1" />
            </label>
            <label className="block">
              <Label>วันหมดอายุ</Label>
              <Input type="text" placeholder="ดด/ปป" className="mt-1" />
            </label>
            <label className="block">
              <Label>รหัส CVC/CVV</Label>
              <Input type="text" className="mt-1" />
            </label>
            <div className="block bg-cyan-50 font-semibold text-blue-600 ">
              <input type="radio"></input>
              <span className=" ml-3">ธนาคารออนไลน์</span>
              <hr />
              <input type="radio"></input>
              <span className=" ml-3">การชำระเงินระบบดิจิทัล</span>
              <hr />
              <input type="radio"></input>
              <span className=" ml-3">เคาน์เตอร์ เซอร์วิส</span>
              <hr />
              <input type="radio"></input>
              <span className=" ml-3">ชำระเงินรายเดือน</span>
              <hr />
              <input type="radio"></input>
              <span className=" ml-3">ชำระเงินด้วยรหัส QR code</span>
              <hr />
            </div>

            <label className="block">
              <input type="checkbox"></input>
              <Label className="m-3">
                ต้องการรับอีเมลแจ้งข่าวโปรโมชั่นพิเศษของอโกด้า
                (ยกเลิกได้ทุกเมื่อดังที่ระบุไว้ในนโยบายความเป็นส่วนตัว)
              </Label>
            </label>
            {/* <div>
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div> */}

            <p>
              ท่านรองรับ
              <a
                className="text-blue-600 underline underline-offset-1 "
                href=""
                target=""
              >
                ข้อกำหนดการใช้งาน
              </a>
              และ
              <a
                className="text-blue-600 underline underline-offset-1"
                href=""
                target=""
              >
                นโยบายความเป็นส่วนตัว
              </a>
              ของอโกด้าเมื่อดำเนินการต่อ
            </p>
            <div className="grid justify-items-end">
              <ButtonPrimary type="submit">จองเลย</ButtonPrimary>
            </div>
          </form>

          <div className=" grid grid-rows-2 sm:grid-cols-1">
            <div className="ml-5 ">
              {/* <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 rounded "> */}
              <div className="max-w-lg listingSection__wrap">
                <span className="bg-blue-500 font-semibold text-white text-center rounded w-40">
                  ห้องราคาถูกที่สุด
                </span>
                <div className="flex justify-between">
                  <span>29 มี.ค.2565 - 30 มี.ค.2565</span>
                  <span>1 คืน</span>
                </div>
                <div className="border-b-2 pb-3">
                  <span>1 x ซูพีเรีย (Superior Room)</span>
                  <a
                    className="text-blue-600 underline underline-offset-1 flex justify-between  "
                    href=""
                    target=""
                  >
                    เปลี่ยน
                  </a>
                </div>

                <div className=" border-b-2 grid grid-cols-1 sm:grid-cols-2">
                  <img
                    className="w-64 mb-3 "
                    src="https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  ></img>
                  <ul className="ml-3">
                    <i className=" las la-bed text-lg"></i>
                    <li>ห้องพัก 1 ห้อง ผู้ใหญ่ 1 คน</li>
                    <i className=" las la-bed text-lg"></i>
                    <li>ผู้ใหญ่ 1 คน</li>
                  </ul>
                </div>
                <p className="text-blue-600 underline underline-offset-1">
                  ขอรับเงินคืนไม่ได้
                </p>
              </div>
            </div>

            <div className="max-w-3xl ml-5 mt-3">
              <div className="max-w-lg listingSection__wrap">
                <div className="flex justify-between ">
                  <h3 className="text-lg font-semibold">ราคาที่จ่าย</h3>
                  <span>฿769.30</span>
                </div>
                <p>รวม: ภาษี 7% เซอร์วิสชาร์จ 10%</p>
                <p className="bg-green-500 font-semibold">
                  ราคาดีที่สุด! ที่ไหนถูกกว่าเราลดให้เท่ากันไปเลย
                </p>
                <p>ดีอะไรอย่างนี้ จองที่พักนี้ได้ส่วนลดตั้ง ฿1,374.59</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      {/* <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div> */}
    </div>
  );
};

export default PageCredit;
