/* eslint-disable */
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper, apiBaseURL } from 'helpers';
import TokenService from "helpers/token.service";

export { useSendMail };
const baseApi = "send-mails";
const waitingTime = 500;
const defaultPerPage = 25;
const defaultPageIndex = 1;

const authAtom = atom({
    key: 'auth',
    default: JSON.parse(localStorage.getItem('user') || '{}')
});

const usersAtom = atom({
    key: 'users',
    default: null
});



function useSendMail() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();

    const setAuth = useSetRecoilState(authAtom);
    const authValue = useRecoilValue(authAtom);

    const setUsers = useSetRecoilState(usersAtom);
    const usersValue = useRecoilValue(usersAtom);

    return {
        onSendMail,
        auth: authValue,
        userInfo: usersValue
    }



    async function onSendMail(_data: any): Promise<any> {
        console.log('onSendMail _data', _data);


        let bookingData = {
            bookingNo: _data.bookingNo,
            createdAt: "2022-04-07T00:48:28.976Z",
            seq: 0,
            createdBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            updatedAt: "2022-04-07T00:48:28.976Z",
            updatedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            deletedAt: "2022-04-07T00:48:28.976Z",
            deletedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            ownerBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            documentDate: "2022-04-07T00:48:28.976Z",
            effectiveAt: "2022-04-07T00:48:28.976Z",
            documentStatusId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            revision: 0,
            isPublished: true,
            id: "string",
            gid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            adultQty: _data.adultQty,
            childQty: _data.childQty,
            infantQty: _data.infantQty,
            roomQty: _data.roomQty,
            roomTypeId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            roomType: _data.roomTypeName,//_data.roomType,

            roomPrice: _data.roomPrice,
            nightQty: _data.nightQty,
            netPrice: _data.price,
            startDate: _data.startDate,
            endDate: _data.endDate,
            extraBedQty: _data.extraBedQty,
            slug: "string",
            body: "string",
            date: "2022-04-07T00:48:28.976Z",
            fullName: _data.fullName,
            email: _data.email,
            confirmEmail: _data.confirmEmail,
            phone: _data.phone,
            placeName: _data.placeName
        }

        console.log('onSendMail', bookingData);

        return fetchWrapper.post(`${baseUrl}/${baseApi}`, bookingData).then((response: any) => {
            setTimeout(() => {
                console.log('booking response ', response)
                // setDatas(response?.data);
                // setTotalRecord(response?.totalRecord);
                // setLoadingPage(false);
                // setLoading(false);

                history.push('/')
                // setTimeout(() => {
                //     // console.log('booking')
                //     // console.log('data >> ', bookingData)
                //     history.push('/')
                //         , 200
                // })

            }, waitingTime);
        });
    }



    function logout() {
        // remove user from local storage, set auth state to null and redirect to login page
        localStorage.removeItem('user');
        setAuth(null);
        history.push('/');
    }

}