/* eslint-disable */
import { useEffect, useMemo, useState } from "react";
import {
    useSetRecoilState,
    useRecoilValue,
    useRecoilState,
    atom,
} from "recoil";
import { history, useFetchWrapper, apiBaseURL } from "helpers";

export { useRoomTypes };
const baseApi = "room-types";
const waitingTime = 500;
const defaultPerPage = 25;
const defaultPageIndex = 1;

const dataAtom = atom({
    key: `${baseApi}Atom`,
    default: [],
});

export interface RateType {
    Id: string;
    HotelId: Number;
    QueryCode: string;

    BDate: Date;
    RmTpCode: string;
    RoomRate: Number;

    ABFRate: Number;
    ExtraBed: Number;
    AbfChildAmt: Number;

    Promotion: string;
    AdultQty: Number;
    Night: Number;
    NetAmt: Number;





}


export interface RatesInfoType {
    dayRates: Array<RateType>,
    netAmmt: Number,
    queryCode: string;
}

const ratesAtom = atom<RatesInfoType>({
    key: `${baseApi}ratesAtom`,
    default: {} as RatesInfoType
});





const listedColumnsAtom = atom({
    key: `${baseApi}listedColumnsAtom`,
    default: [],
});

const linksAtom = atom({
    key: `${baseApi}linksAtom`,
    default: {},
});


const searchCriteriaAtom = atom({
    key: `${baseApi}searchCriteriaAtom`,
    default: {
        criteria: {
            searchText: "",
        },
        pageIndex: defaultPageIndex,
        pageSize: defaultPerPage,
    },
});



const perPageAtom = atom({
    key: `${baseApi}perPageAtom`,
    default: 25,
});

const pageIndexAtom = atom({
    key: `${baseApi}pageIndexAtom`,
    default: 1,
});

const totalRecordAtom = atom({
    key: `${baseApi}totalRecordAtom`,
    default: 1,
});


function useRoomTypes() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();

    const [loading, setLoading] = useState(true);
    const [loadingPage, setLoadingPage] = useState(true);
    const [updating, setUpdating] = useState(false);

    const [totalRecord, setTotalRecord] = useState(totalRecordAtom);
    const [perPage, setPerPage] = useRecoilState(perPageAtom);
    const [pageIndex, setPageIndex] = useRecoilState(pageIndexAtom);
    const [searchCriteria, setSearchCriteria] = useRecoilState(
        searchCriteriaAtom
    );

    const [datas, setDatas] = useRecoilState(dataAtom);
    const [rates, setRates] = useRecoilState(ratesAtom);

    const [listedColumns, setListedColumns] = useRecoilState(listedColumnsAtom);
    const [links, setLinks] = useRecoilState(linksAtom);

    // const [options, setOptions] = useRecoilState(dataOptionAtom);

    // useMemo(() => {
    //     //   getall();
    //     let searchCri = {
    //         criteria: {
    //             searchText: searchCriteria.criteria.searchText,
    //         },
    //         pageIndex: pageIndex,
    //         pageSize: perPage,
    //     };
    //     search(searchCri);
    // }, []);

    // useMemo(() => {
    //     let searchCri = {
    //         criteria: {
    //             searchText: searchCriteria.criteria.searchText,
    //         },
    //         pageIndex: pageIndex,
    //         pageSize: perPage,
    //     };
    //     search(searchCri);
    // }, [searchCriteria, pageIndex]);



    const getAll = async (req: any): Promise<any> => {


        return fetchWrapper.post(`${baseUrl}/${baseApi}/all`, req).then((response: any) => {
            setTimeout(() => {
                setDatas(response);
                //setTotalRecord(response?.totalRecord);
                setLoadingPage(false);
                setLoading(false);
            }, waitingTime);
        });
    }


    const getRates = async (req: any): Promise<any> => {

        // {
        //     "startDate": "2022-04-17T04:26:18.787Z",
        //     "endDate": "2022-04-20T04:26:18.787Z",
        //     "roomTypeCode": "STS"
        //   }

        console.log(' req getrates:', req)


        return fetchWrapper.post(`${baseUrl}/${baseApi}/rates`, req).then((response: any) => {
            setTimeout(() => {
                setRates(response);
                console.log('get rates response:', response)
                //setTotalRecord(response?.totalRecord);
                setLoadingPage(false);
                setLoading(false);
            }, waitingTime);
        });
    }

    //  async function getAllBooking(url: string): Promise<any[]> => {

    //     return fetchWrapper.get(`${baseUrl}/${baseApi}`).then((response: any) => {
    //         setTimeout(() => {
    //             setData(response?.data);
    //             setTotalRecord(response?.totalRecord);
    //             setLoadingPage(false);
    //             setLoading(false);
    //         }, waitingTime);
    //     });

    //     // const response = await fetch(url)
    //     // const { data } = await response.json()
    //     // return data
    // }


    // async function getall1(): Promise<any> => {
    //     return fetchWrapper.get(`${baseUrl}/${baseApi}`).then((response: any) => {
    //         setTimeout(() => {
    //             setData(response?.data);
    //             setTotalRecord(response?.totalRecord);
    //             setLoadingPage(false);
    //             setLoading(false);
    //         }, waitingTime);
    //     });
    // }



    // async function deleteById(id) {
    //     //setLoadingPage(true);
    //     return fetchWrapper
    //         .delete(`${baseUrl}/${baseApi}/${id}`)
    //         .then((response) => {
    //             // setData(response?.data);
    //             // setTotalRecord(response?.totalRecord);
    //             // setTimeout(() => {
    //             //     setLoading(false);
    //             //     setLoadingPage(false)
    //             // }, waitingTime);
    //         });
    // }

    // async function getById(id) {
    //     setLoading(true);
    //     return fetchWrapper.get(`${baseUrl}/${baseApi}/${id}`).then((response) => {
    //         setData(response);
    //         setTotalRecord(1);
    //         setTimeout(() => {
    //             setLoading(false);
    //         }, waitingTime);
    //     });
    // }

    // async function onCreateMyTestAnswer(req) {
    //     return await createMyTestAnswer(req); // testId, answerId
    // }

    // async function createMyTestAnswer(req) {
    //     setLoading(true);
    //     return fetchWrapper.post(`${baseUrl}/${baseApi}/${req.testId}/${req.answerId}/createAnswer`, req).then((response) => {
    //         setTotalRecord(1);
    //         setTimeout(() => {
    //             setLoading(false);
    //         }, waitingTime);
    //     });
    // }

    // async function updateById(id, req) {
    //     setUpdating(true);

    //     console.log(`${baseUrl}/${baseApi}/${id}`);

    //     return fetchWrapper
    //         .put(`${baseUrl}/${baseApi}/${id}`, req)
    //         .then((response) => {
    //             // setData(response?.data);
    //             // setTotalRecord(response?.totalRecord);
    //             setTimeout(() => {
    //                 setUpdating(false);
    //                 //setLoading(false);
    //                 //setLoadingPage(false)
    //             }, waitingTime);
    //         });
    // }

    // async function create(req) {
    //     //setLoadingPage(true);
    //     return fetchWrapper.post(`${baseUrl}/${baseApi}/create`, req).then((response) => {
    //         // setData(response?.data);
    //         // setTotalRecord(response?.totalRecord);
    //         // setTimeout(() => {
    //         //     setLoading(false);
    //         //     setLoadingPage(false)
    //         // }, waitingTime);
    //     });
    // }

    // async function onUpdateById(id, req) {
    //     updateById(id, req);
    // }

    // async function onCreate(req) {
    //     create(req);
    // }

    // async function onGetById(id) {
    //     await getById(id);
    // }

    // async function onDeleteById(_id) {
    //     //var index = data.findIndex(({ id }) => id == _id);
    //     let newData = datas.filter(({ id }) => id !== _id);
    //     const newTotalRecord = totalRecord - 1;
    //     setTotalRecord(newTotalRecord);
    //     setData(newData);
    //     deleteById(_id);
    // }

    // function onPageChanged(data) {
    //     const { currentPage, totalPages, pageLimit } = data;
    //     setPageIndex(currentPage);
    // }

    // async function getall() {
    //     return fetchWrapper.get(`${baseUrl}/${baseApi}`).then((response) => {
    //         setTimeout(() => {
    //             setData(response?.data);
    //             setTotalRecord(response?.totalRecord);
    //             setLoadingPage(false);
    //             setLoading(false);
    //         }, waitingTime);
    //     });
    // }

    // async function onDeleteById(_id) {
    //     //var index = data.findIndex(({ id }) => id == _id);
    //     let newData = datas.filter(({ id }) => id !== _id);
    //     const newTotalRecord = totalRecord - 1;
    //     setTotalRecord(newTotalRecord);
    //     setData(newData);
    //     deleteById(_id);
    // }


    // async function search(criteria) {
    //     setLoadingPage(true);
    //     return fetchWrapper
    //         .post(`${baseUrl}/${baseApi}`, criteria)
    //         .then((response) => {
    //             response?.data?.map((itm) => itm.detailLinkUrl = response?.refs?.links?.find(({ linkName }) => linkName == 'listed')?.linkUrl);
    //             let _links =
    //             {
    //                 listedLinkUrl: response?.refs?.links?.find(({ linkName }) => linkName == 'listed')?.linkUrl,
    //                 createLinkUrl: response?.refs?.links?.find(({ linkName }) => linkName == 'create')?.linkUrl,
    //                 updateLinkUrl: response?.refs?.links?.find(({ linkName }) => linkName == 'update')?.linkUrl,
    //                 deleteLinkUrl: response?.refs?.links?.find(({ linkName }) => linkName == 'delete')?.linkUrl,
    //             }
    //             setLinks(_links);
    //             setData(response?.data);
    //             setTotalRecord(response?.totalRecord);
    //             setListedColumns(response?.refs?.columns);

    //             setTimeout(() => {
    //                 setLoading(false);
    //                 setLoadingPage(false);
    //             }, waitingTime);
    //         });
    // }

    // async function deleteById(id) {
    //     //setLoadingPage(true);
    //     return fetchWrapper
    //         .delete(`${baseUrl}/${baseApi}/${id}`)
    //         .then((response) => {
    //             // setData(response?.data);
    //             // setTotalRecord(response?.totalRecord);
    //             // setTimeout(() => {
    //             //     setLoading(false);
    //             //     setLoadingPage(false)
    //             // }, waitingTime);
    //         });
    // }



    // function onPageChanged(data) {
    //     const { currentPage, totalPages, pageLimit } = data;
    //     setPageIndex(currentPage);
    // }

    // function onSearch(criteria) {
    //     setPageIndex(1);
    //     setSearchCriteria(criteria);
    // }


    return {
        getAll, getRates,
        //options,
        // onPageChanged,
        // onSearch,
        // onDeleteById,
        // onCreate,
        // onGetById, onCreateMyTestAnswer,
        // onUpdateById,
        totalRecord,
        perPage,
        pageIndex,
        searchCriteria,
        datas, rates, listedColumns, links,
        loading,
        loadingPage,
        updating,
    };

}
