import React, { FC, Fragment, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderFacilities from "components/SectionSliderFacilities/SectionSliderFacilities";
import useWindowSize from "hooks/useWindowResize";
import ModalPhotos from "./ModalPhotos";
import { TaxonomyType } from "data/types";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { Link } from "react-router-dom";

export interface PageFacilitiesProps {
  className?: string;
  isPreviewMode?: boolean;
  size?: "default" | "small";
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter",
  },
  {
    title: "💌 EMAIL",
    desc: "nc.example@example.com",
  },
  {
    title: "☎ PHONE",
    desc: "000-123-456-7890",
  },
];

// const FacilitiesRestaurant: TaxonomyType[] = [
//   {
//     id: "1",
//     href: "#",
//     name: "Enjoy the Beauty of Brazil ",
//     taxonomy: "category",
//     // count: 17288,
//     thumbnail:
//       "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-restaurant-201",
//     listingType: "experiences",
//   },
//   {
//     id: "2",
//     href: "#",
//     name: "Enjoy the Beauty of Paris",
//     taxonomy: "category",
//     // count: 2118,
//     thumbnail:
//       "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-restaurant-202",
//     listingType: "experiences",
//   },
//   {
//     id: "3",
//     href: "#",
//     name: "Enjoy the Beauty of Bangkok",
//     taxonomy: "category",
//     // count: 36612,
//     thumbnail:
//       "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-restaurant-203",
//     listingType: "experiences",
//   },
//   {
//     id: "5",
//     href: "#",
//     name: "Enjoy the Beauty of Singapore",
//     taxonomy: "category",
//     // count: 188288,
//     thumbnail:
//       "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-restaurant-204",
//     listingType: "experiences",
//   },
//   {
//     id: "4",
//     href: "#",
//     name: "Enjoy the Beauty of Seoul",
//     taxonomy: "category",
//     // count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/373290/pexels-photo-373290.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//     listingType: "experiences",
//   },
// ];

const FacilitiesRestaurant = [
  "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-restaurant-201",
  "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-restaurant-202",
  "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-restaurant-203",
  "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-restaurant-204",
  "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-restaurant-205",
  "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-restaurant-206",
  "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-restaurant-207"
];

// const FacilitiesSwimmingPool: TaxonomyType[] = [
//   {
//     id: "1",
//     href: "#",
//     name: "Enjoy the Beauty of Brazil ",
//     taxonomy: "category",
//     // count: 17288,
//     thumbnail:
//       "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-swimmingpool-301",
//     listingType: "experiences",
//   },
//   {
//     id: "2",
//     href: "#",
//     name: "Enjoy the Beauty of Brazil ",
//     taxonomy: "category",
//     // count: 17288,
//     thumbnail:
//       "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-swimmingpool-302",
//     listingType: "experiences",
//   },
//   {
//     id: "3",
//     href: "#",
//     name: "Enjoy the Beauty of Brazil ",
//     taxonomy: "category",
//     // count: 17288,
//     thumbnail:
//       "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-swimmingpool-303",
//     listingType: "experiences",
//   },
//   {
//     id: "4",
//     href: "#",
//     name: "Enjoy the Beauty of Brazil ",
//     taxonomy: "category",
//     // count: 17288,
//     thumbnail:
//       "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-swimmingpool-304",
//     listingType: "experiences",
//   },
// ];

// const FacilitiesSpaMassage: TaxonomyType[] = [
//   {
//     id: "1",
//     href: "#",
//     name: "Enjoy the Beauty of Brazil ",
//     taxonomy: "category",
//     // count: 17288,
//     thumbnail:
//       "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-restaurant-201",
//     listingType: "experiences",
//   },
// ];


const FacilitiesSwimmingPool = [
  "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-swimmingpool-301",
  "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-swimmingpool-302",
  "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-swimmingpool-303",
  "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-swimmingpool-304",
];

// const FacilitiesSpaMassage: TaxonomyType[] = [
//   {
//     id: "1",
//     href: "#",
//     name: "Enjoy the Beauty of Brazil ",
//     taxonomy: "category",
//     // count: 17288,
//     thumbnail:
//       "https://hotel-imagestore-api.devz.sbs/api/image-store/image/facilities-restaurant-201",
//     listingType: "experiences",
//   },
// ];

const FacilitiesSpaMassage = [
  "https://hotel-imagestore-api.devz.sbs/api/image-store/image/spa-806",
];
//------------------------------------------------------------------------------//
const PageFacilities: FC<PageFacilitiesProps> = ({
  className = "",
  size = "default",
  isPreviewMode,
}) => {
  const [isOpenRestaurant, setIsOpenRestaurant] = useState(false);
  const [openFocusRestaurantIndex, setOpenFocusRestaurantIndex] = useState(0);

  const handleOpenRestaurantModal = (index: number) => {
    setIsOpenRestaurant(true);
    setOpenFocusRestaurantIndex(index);
  };

  const handleCloseRestaurantModal = () => setIsOpenRestaurant(false);
  //----------------------------------------------------------------------------------------//
  const [isOpenSwimmingPool, setIsOpenSwimmingPool] = useState(false);
  const [openFocusSwimmingPoolIndex, setOpenFocusSwimmingPoolIndex] =
    useState(0);

  const handleOpenSwimmingPoolModal = (index: number) => {
    setIsOpenSwimmingPool(true);
    setOpenFocusSwimmingPoolIndex(index);
  };

  const handleCloseSwimmingPoolModal = () => setIsOpenSwimmingPool(false);
  //---------------------------------------------------------------------------------------//
  const [isOpenSpaMassage, setIsOpenSpaMassage] = useState(false);
  const [openFocusSpaMassageIndex, setOpenFocusSpaMassageIndex] = useState(0);

  const handleOpenSpaMassageModal = (index: number) => {
    setIsOpenSpaMassage(true);
    setOpenFocusSpaMassageIndex(index);
  };

  const handleCloseSpaMassageModal = () => setIsOpenSpaMassage(false);
  //------------------------------------------------------------------------------//

  const renderSliderGalleryRestaurant = () => {
    return (
      <div className="relative w-full rounded-2xl overflow-hidden">
        <GallerySlider
          uniqueID={`FacilitiesRestaurantCard-${1}`}
          ratioClass={"aspect-w-3 aspect-h-3"}
          galleryImgs={FacilitiesRestaurant}
          handleOnImgClick={handleOpenRestaurantModal}
        />
      </div>
    );
  };
  const renderContentRestaurant = () => {
    return (
      <div className={size === "default" ? "py-4 space-y-4" : "py-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${size === "default" ? "text-base" : "text-base"
                }`}
            >
              <span className="line-clamp-1">Restaurant</span>
            </h2>
          </div>
        </div>
      </div>
    );
  };
  //-------------------------------------------------------------------------------------------//
  const renderSliderGallerySwimmingPool = () => {
    return (
      <div className="relative w-full rounded-2xl overflow-hidden">
        <GallerySlider
          uniqueID={`FacilitiesSwimmingPoolCard-${1}`}
          ratioClass={"aspect-w-3 aspect-h-3"}
          galleryImgs={FacilitiesSwimmingPool}
          handleOnImgClick={handleOpenSwimmingPoolModal}
        />
      </div>
    );
  };
  const renderContentSwimmingPool = () => {
    return (
      <div className={size === "default" ? "py-4 space-y-4" : "py-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${size === "default" ? "text-base" : "text-base"
                }`}
            >
              <span className="line-clamp-1">Swimming Pool</span>
            </h2>
          </div>
        </div>
      </div>
    );
  };
  //-------------------------------------------------------------------------------------//
  const renderSliderGallerySpaMassage = () => {
    return (
      <div className="relative w-full rounded-2xl overflow-hidden">
        <GallerySlider
          uniqueID={`FacilitiesSpaMassageCard-${1}`}
          ratioClass={"aspect-w-3 aspect-h-3"}
          galleryImgs={FacilitiesSpaMassage}
          handleOnImgClick={handleOpenSpaMassageModal}
        />
      </div>
    );
  };
  const renderContentSpaMassage = () => {
    return (
      <div className={size === "default" ? "py-4 space-y-4" : "py-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${size === "default" ? "text-base" : "text-base"
                }`}
            >
              <span className="line-clamp-1">Spa & Massage</span>
            </h2>
          </div>
        </div>
      </div>
    );
  };
  //-------------------------------------------------------------------------------------//
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact || Booking React Template</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Facilities
        </h2>

        {/* <div className="container relative"> */}
        <div className="container ">
          {/* <div className="relative py-16"> */}
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {/* <SectionSliderFacilities
              heading="Explore top destination ✈"
              subHeading="Explore thousands of destinations around the world"
              categoryCardType="card4"
              itemPerRow={3}
              sliderStyle="style2"
              handleOnImgClick={handleOpenRestaurantModal}
            /> */}
            <div className="relative w-full rounded-2xl overflow-hidden">
              <div>
                <Link to="#">{renderSliderGalleryRestaurant()}</Link>
              </div>
              <div className="flex justify-center">
                {renderContentRestaurant()}
              </div>
            </div>

            <div className="relative w-full rounded-2xl overflow-hidden">
              <div>
                <Link to="#">{renderSliderGallerySwimmingPool()}</Link>
              </div>
              <div className="flex justify-center">
                {renderContentSwimmingPool()}
              </div>
            </div>

            <div className="relative w-full rounded-2xl overflow-hidden">
              <div>
                <Link to="#">{renderSliderGallerySpaMassage()} </Link>
              </div>
              <div className="flex justify-center">
                {renderContentSpaMassage()}
              </div>
            </div>

            <ModalPhotos
              imgs={FacilitiesRestaurant}
              isOpen={isOpenRestaurant}
              onClose={handleCloseRestaurantModal}
              initFocus={openFocusRestaurantIndex}
            // isOpenPicture={isOpenPictureRestaurant}
            />

            {/* <SectionSliderFacilities
              heading="Explore top destination ✈"
              subHeading="Explore thousands of destinations around the world"
              categoryCardType="card4"
              itemPerRow={3}
              categories={FacilitiesSwimmingPool}
              sliderStyle="style2"
              handleOnImgClick={handleOpenSwimmingPoolModal}
            />*/}
            <ModalPhotos
              imgs={FacilitiesSwimmingPool}
              isOpen={isOpenSwimmingPool}
              onClose={handleCloseSwimmingPoolModal}
              initFocus={openFocusSwimmingPoolIndex}
            // isOpenPicture={isOpenPictureRestaurant}
            />

            <ModalPhotos
              imgs={FacilitiesSpaMassage}
              isOpen={isOpenSpaMassage}
              onClose={handleCloseSpaMassageModal}
              initFocus={openFocusSpaMassageIndex}
            // isOpenPicture={isOpenPictureRestaurant}
            />

            {/* <SectionSliderFacilities
              heading="Explore top destination ✈"
              subHeading="Explore thousands of destinations around the world"
              categoryCardType="card4"
              itemPerRow={4}
              categories={FacilitiesSpaMassage}
              sliderStyle="style2"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageFacilities;
