import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridFilterCard from "./SectionGridFilterCard";
import ModalPhotos from "components/ModalPhotos/ModalPhotos";

const { REACT_APP_ASE_IMAGE_API_URL } = process.env;

export interface PageAccommodataionProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter",
  },
  {
    title: "💌 EMAIL",
    desc: "nc.example@example.com",
  },
  {
    title: "☎ PHONE",
    desc: "000-123-456-7890",
  },
];



const PhotosStandardDouble: string[] = [
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype1-1`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype1-2`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype1-3`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype1-4`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype1-5`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype1-6`,
];

const PageAccommodataion: FC<PageAccommodataionProps> = ({ className = "" }) => {

  const [isOpenRoomType, setIsOpenRoomType] = useState(false);
  const [modalPhotos, setModalPhotos] = useState([]);

  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const handleCloseRooomTypeModal = () => setIsOpenRoomType(false);

  const handlShowPhotos = () => {
    setIsOpenRoomType(true)

  }

  useEffect(() => {
    if (modalPhotos.length > 0)
      setIsOpenRoomType(true)
  }, [modalPhotos])


  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact || Booking React Template</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Accommodation
        </h2>
        <div className="container relative">


          <SectionGridFilterCard className="pb-24 lg:pb-32" handleOnClick={handlShowPhotos} setModalPhotos={setModalPhotos} />


          {isOpenRoomType &&
            <ModalPhotos
              imgs={modalPhotos}
              isOpen={isOpenRoomType}
              onClose={handleCloseRooomTypeModal}
              initFocus={openFocusIndex}
            />
          }

        </div>
      </div>
    </div>
  );
};

export default PageAccommodataion;
