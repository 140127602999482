/* eslint-disable */
import axios from "axios";
import TokenService from "./token.service";

const { REACT_APP_API_URL, REACT_APP_API_URL_STAGING, REACT_APP_API_URL_PROD, REACT_APP_API_URL_DEV, REACT_APP_ENV } = process.env;

const instance = axios.create({
    baseURL: REACT_APP_ENV == "production" ? REACT_APP_API_URL_PROD : (REACT_APP_ENV == "staging" ? REACT_APP_API_URL_STAGING : (REACT_APP_ENV == "development" ? REACT_APP_API_URL_DEV : REACT_APP_API_URL)),
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    async (config) => {
        const token = await TokenService.getAccessToken();

        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    async (res) => {

        const originalConfig = res.config;
        if (originalConfig.url == "/accounts/login") {
            // Access Token was expired
            if (res.status === 200) {
                const { accessToken, refreshToken } = res?.data?.data;
                await TokenService.setAccessToken(accessToken);
                await TokenService.setRefreshToken(refreshToken);
            }
        }

        return res?.data;
    },
    async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== "/accounts/login" && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                console.log('------ **** renew token **** -----');

                try {
                    const _token = await TokenService.getAccessToken();
                    const _refreshToken = await TokenService.getRefreshToken();
                    const res = await instance.post("/accounts/refresh", {
                        refreshToken: _refreshToken,
                        accessToken: _token
                    });
                    const { accessToken, refreshToken } = res?.data;
                    await TokenService.setAccessToken(accessToken)
                    await TokenService.setRefreshToken(refreshToken);
                    return instance(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }
        return Promise.reject(err);
    }
);

export default instance;
