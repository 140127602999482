/* eslint-disable */
import { useEffect, useMemo, useState } from "react";
import {
    useSetRecoilState,
    useRecoilValue,
    useRecoilState,
    atom,
} from "recoil";
import { history, useFetchWrapper, apiBaseURL } from "helpers";
import moment from "moment";

export { useBooking };
const baseApi = "bookings";
const waitingTime = 500;
const defaultPerPage = 25;
const defaultPageIndex = 1;


export interface Booking {
    roomType: string;
    adultQty: string;
    childQty: string;
    infantQty: string;
    roomQty: string;
    extraBedQty: string;
}

export interface StartDate {
    startDate: Date;
}

export interface EndDate {
    endDate: Date;
}

const dataAtom = atom<Booking[]>({
    key: `${baseApi}dataAtom`,
    default: [],
});

const datasAtom = atom({
    key: `${baseApi}datasAtom`,
    default: [],
});

const startDateAtom = atom({
    key: `${baseApi}startDateAtom`,
    default: null,
});


const endDateAtom = atom({
    key: `${baseApi}endDateAtom`,
    default: null,
});


const examTestsAtom = atom({
    key: `${baseApi}examTestsAtom`,
    default: [],
});


const listedColumnsAtom = atom({
    key: `${baseApi}listedColumnsAtom`,
    default: [],
});

const linksAtom = atom({
    key: `${baseApi}linksAtom`,
    default: {},
});


const searchCriteriaAtom = atom({
    key: `${baseApi}searchCriteriaAtom`,
    default: {
        criteria: {
            searchText: "",
        },
        pageIndex: defaultPageIndex,
        pageSize: defaultPerPage,
    },
});

const dataOptionAtom = atom({
    key: `${baseApi}OptionsAtom`,
    default: [],
});

const perPageAtom = atom({
    key: `${baseApi}perPageAtom`,
    default: 25,
});

const pageIndexAtom = atom({
    key: `${baseApi}pageIndexAtom`,
    default: 1,
});

const totalRecordAtom = atom({
    key: `${baseApi}totalRecordAtom`,
    default: 1,
});


function useBooking() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();

    const [loading, setLoading] = useState(true);
    const [loadingPage, setLoadingPage] = useState(true);
    const [updating, setUpdating] = useState(false);

    const [totalRecord, setTotalRecord] = useState(totalRecordAtom);
    const [perPage, setPerPage] = useRecoilState(perPageAtom);
    const [pageIndex, setPageIndex] = useRecoilState(pageIndexAtom);
    const [searchCriteria, setSearchCriteria] = useRecoilState(
        searchCriteriaAtom
    );

    const [datas, setDatas] = useRecoilState(datasAtom);
    const [bookingData, setBookingData] = useRecoilState(dataAtom);
    const [startDate, setStartDate] = useRecoilState(startDateAtom);
    const [endDate, setEndDate] = useRecoilState(endDateAtom);

    const [examTests, setExamTests] = useRecoilState(examTestsAtom);
    const [listedColumns, setListedColumns] = useRecoilState(listedColumnsAtom);
    const [links, setLinks] = useRecoilState(linksAtom);

    // const [options, setOptions] = useRecoilState(dataOptionAtom);

    // useMemo(() => {
    //     //   getall();
    //     let searchCri = {
    //         criteria: {
    //             searchText: searchCriteria.criteria.searchText,
    //         },
    //         pageIndex: pageIndex,
    //         pageSize: perPage,
    //     };
    //     search(searchCri);
    // }, []);

    // useMemo(() => {
    //     let searchCri = {
    //         criteria: {
    //             searchText: searchCriteria.criteria.searchText,
    //         },
    //         pageIndex: pageIndex,
    //         pageSize: perPage,
    //     };
    //     search(searchCri);
    // }, [searchCriteria, pageIndex]);

    const onSetData = async (_data: any): Promise<any> => {
        console.log('onSetData :: _data', _data);
        setBookingData(_data)
        setTimeout(() => {
            // console.log('booking')
            // console.log('data >> ', bookingData)
            history.push('/booking')
                , 1000
        })
    }

    const onSetStartDate = async (_data: any): Promise<any> => {
        console.log('onSetStartDate :: _data', _data);
        setStartDate(_data)

    }

    const onSetEndDate = async (_data: any): Promise<any> => {
        console.log('onSetEndDate :: _data', _data);
        setEndDate(_data)
    }



    const getAllBooking = async (): Promise<any> => {
        return fetchWrapper.get(`${baseUrl}/${baseApi}`).then((response: any) => {
            setTimeout(() => {
                setDatas(response?.data);
                setTotalRecord(response?.totalRecord);
                setLoadingPage(false);
                setLoading(false);
            }, waitingTime);
        });
    }


    const onBooking = async (_data: any): Promise<any> => {

        console.log('onBooking _data', _data);


        let bookingData = {
            createdAt: "2022-04-07T00:48:28.976Z",
            seq: 0,
            createdBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            updatedAt: "2022-04-07T00:48:28.976Z",
            updatedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            deletedAt: "2022-04-07T00:48:28.976Z",
            deletedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            ownerBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            documentDate: "2022-04-07T00:48:28.976Z",
            effectiveAt: "2022-04-07T00:48:28.976Z",
            documentStatusId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            revision: 0,
            isPublished: true,
            id: "string",
            gid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            adultQty: _data.adultQty,
            childQty: _data.childQty,
            infantQty: _data.infantQty,
            roomQty: _data.roomQty,
            roomTypeId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            roomType: _data.roomType,
            netPrice: _data.price,
            startDate: _data.startDate,
            endDate: _data.endDate,
            extraBedQty: _data.extraBedQty,
            slug: "string",
            body: "string",
            date: "2022-04-07T00:48:28.976Z",
            fullName: _data.fullName,
            email: _data.email,
            confirmEmail: _data.confirmEmail,
            phone: _data.phone,
            placeName: _data.placeName,
            queryCode: _data.queryCode
        }

        console.log('onBooking', bookingData);

        return fetchWrapper.post(`${baseUrl}/${baseApi}`, bookingData).then((response: any) => {
            return response;
            setTimeout(() => {
                //console.log('booking response ', response)
                // setDatas(response?.data);
                // setTotalRecord(response?.totalRecord);
                // setLoadingPage(false);
                // setLoading(false);

                //history.push('/')
                // setTimeout(() => {
                //     // console.log('booking')
                //     // console.log('data >> ', bookingData)
                //     history.push('/')
                //         , 200
                // })

            }, waitingTime);
        });
    }



    //  async function getAllBooking(url: string): Promise<any[]> => {

    //     return fetchWrapper.get(`${baseUrl}/${baseApi}`).then((response: any) => {
    //         setTimeout(() => {
    //             setData(response?.data);
    //             setTotalRecord(response?.totalRecord);
    //             setLoadingPage(false);
    //             setLoading(false);
    //         }, waitingTime);
    //     });

    //     // const response = await fetch(url)
    //     // const { data } = await response.json()
    //     // return data
    // }


    // async function getall1(): Promise<any> => {
    //     return fetchWrapper.get(`${baseUrl}/${baseApi}`).then((response: any) => {
    //         setTimeout(() => {
    //             setData(response?.data);
    //             setTotalRecord(response?.totalRecord);
    //             setLoadingPage(false);
    //             setLoading(false);
    //         }, waitingTime);
    //     });
    // }



    // async function deleteById(id) {
    //     //setLoadingPage(true);
    //     return fetchWrapper
    //         .delete(`${baseUrl}/${baseApi}/${id}`)
    //         .then((response) => {
    //             // setData(response?.data);
    //             // setTotalRecord(response?.totalRecord);
    //             // setTimeout(() => {
    //             //     setLoading(false);
    //             //     setLoadingPage(false)
    //             // }, waitingTime);
    //         });
    // }

    // async function getById(id) {
    //     setLoading(true);
    //     return fetchWrapper.get(`${baseUrl}/${baseApi}/${id}`).then((response) => {
    //         setData(response);
    //         setTotalRecord(1);
    //         setTimeout(() => {
    //             setLoading(false);
    //         }, waitingTime);
    //     });
    // }

    // async function onCreateMyTestAnswer(req) {
    //     return await createMyTestAnswer(req); // testId, answerId
    // }

    // async function createMyTestAnswer(req) {
    //     setLoading(true);
    //     return fetchWrapper.post(`${baseUrl}/${baseApi}/${req.testId}/${req.answerId}/createAnswer`, req).then((response) => {
    //         setTotalRecord(1);
    //         setTimeout(() => {
    //             setLoading(false);
    //         }, waitingTime);
    //     });
    // }

    // async function updateById(id, req) {
    //     setUpdating(true);

    //     console.log(`${baseUrl}/${baseApi}/${id}`);

    //     return fetchWrapper
    //         .put(`${baseUrl}/${baseApi}/${id}`, req)
    //         .then((response) => {
    //             // setData(response?.data);
    //             // setTotalRecord(response?.totalRecord);
    //             setTimeout(() => {
    //                 setUpdating(false);
    //                 //setLoading(false);
    //                 //setLoadingPage(false)
    //             }, waitingTime);
    //         });
    // }

    // async function create(req) {
    //     //setLoadingPage(true);
    //     return fetchWrapper.post(`${baseUrl}/${baseApi}/create`, req).then((response) => {
    //         // setData(response?.data);
    //         // setTotalRecord(response?.totalRecord);
    //         // setTimeout(() => {
    //         //     setLoading(false);
    //         //     setLoadingPage(false)
    //         // }, waitingTime);
    //     });
    // }

    // async function onUpdateById(id, req) {
    //     updateById(id, req);
    // }

    // async function onCreate(req) {
    //     create(req);
    // }

    // async function onGetById(id) {
    //     await getById(id);
    // }

    // async function onDeleteById(_id) {
    //     //var index = data.findIndex(({ id }) => id == _id);
    //     let newData = datas.filter(({ id }) => id !== _id);
    //     const newTotalRecord = totalRecord - 1;
    //     setTotalRecord(newTotalRecord);
    //     setData(newData);
    //     deleteById(_id);
    // }

    // function onPageChanged(data) {
    //     const { currentPage, totalPages, pageLimit } = data;
    //     setPageIndex(currentPage);
    // }

    // async function getall() {
    //     return fetchWrapper.get(`${baseUrl}/${baseApi}`).then((response) => {
    //         setTimeout(() => {
    //             setData(response?.data);
    //             setTotalRecord(response?.totalRecord);
    //             setLoadingPage(false);
    //             setLoading(false);
    //         }, waitingTime);
    //     });
    // }

    // async function onDeleteById(_id) {
    //     //var index = data.findIndex(({ id }) => id == _id);
    //     let newData = datas.filter(({ id }) => id !== _id);
    //     const newTotalRecord = totalRecord - 1;
    //     setTotalRecord(newTotalRecord);
    //     setData(newData);
    //     deleteById(_id);
    // }


    // async function search(criteria) {
    //     setLoadingPage(true);
    //     return fetchWrapper
    //         .post(`${baseUrl}/${baseApi}`, criteria)
    //         .then((response) => {
    //             response?.data?.map((itm) => itm.detailLinkUrl = response?.refs?.links?.find(({ linkName }) => linkName == 'listed')?.linkUrl);
    //             let _links =
    //             {
    //                 listedLinkUrl: response?.refs?.links?.find(({ linkName }) => linkName == 'listed')?.linkUrl,
    //                 createLinkUrl: response?.refs?.links?.find(({ linkName }) => linkName == 'create')?.linkUrl,
    //                 updateLinkUrl: response?.refs?.links?.find(({ linkName }) => linkName == 'update')?.linkUrl,
    //                 deleteLinkUrl: response?.refs?.links?.find(({ linkName }) => linkName == 'delete')?.linkUrl,
    //             }
    //             setLinks(_links);
    //             setData(response?.data);
    //             setTotalRecord(response?.totalRecord);
    //             setListedColumns(response?.refs?.columns);

    //             setTimeout(() => {
    //                 setLoading(false);
    //                 setLoadingPage(false);
    //             }, waitingTime);
    //         });
    // }

    // async function deleteById(id) {
    //     //setLoadingPage(true);
    //     return fetchWrapper
    //         .delete(`${baseUrl}/${baseApi}/${id}`)
    //         .then((response) => {
    //             // setData(response?.data);
    //             // setTotalRecord(response?.totalRecord);
    //             // setTimeout(() => {
    //             //     setLoading(false);
    //             //     setLoadingPage(false)
    //             // }, waitingTime);
    //         });
    // }



    // function onPageChanged(data) {
    //     const { currentPage, totalPages, pageLimit } = data;
    //     setPageIndex(currentPage);
    // }

    // function onSearch(criteria) {
    //     setPageIndex(1);
    //     setSearchCriteria(criteria);
    // }


    return {
        getAllBooking, onBooking,
        onSetData,
        onSetStartDate,
        onSetEndDate,
        //options,
        // onPageChanged,
        // onSearch,
        // onDeleteById,
        // onCreate,
        // onGetById, onCreateMyTestAnswer,
        // onUpdateById,
        startDate, endDate,
        totalRecord,
        perPage,
        pageIndex,
        searchCriteria,
        datas, bookingData, listedColumns, links, examTests,
        loading,
        loadingPage,
        updating,
    };

}
