import React, { FC, Fragment, useState, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon } from "@heroicons/react/outline";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import StartRating from "components/StartRating/StartRating";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import { DayPickerRangeController, FocusedInputShape } from "react-dates";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalRoomTypePhotos from "./ModalRoomTypePhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionHero from "components/SectionHero/SectionHero";
import SectionVideos from "./SectionVideos";

import Select from "shared/Select/Select";
import Label from "components/Label/Label";
import { useBooking } from "hooks/useBooking";
import { useRoomTypes } from "hooks/useRoomTypes";
import { useModalRoomType } from "hooks/useModalRoomType";
import { history } from 'helpers';


export interface PageHomeMhotelProps {
  className?: string;
  isPreviewMode?: boolean;
}

const { REACT_APP_ASE_IMAGE_API_URL } = process.env;

const PHOTOS1: string[] = [
  "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
];

const PhotosStandardDouble: string[] = [
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype1-1`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype1-2`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype1-3`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype1-4`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype1-5`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype1-6`,
];

const PhotosStandardTwin: string[] = [
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype2-1`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype2-2`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype2-3`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype2-4`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype2-5`,
];

const PhotosDeluxe: string[] = [
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype3-1`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype3-2`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype3-3`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype3-4`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype3-5`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype3-6`,
];

const PhotosConnect: string[] = [
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype4-1`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype4-2`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype4-3`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype4-4`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype4-5`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype4-6`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype4-7`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype4-8`,
];

const PhotosCorner: string[] = [
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype5-1`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype5-2`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype5-3`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype5-4`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype5-5`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype5-6`,
];

const PHOTOS: string[] = [
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-new-101`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-new-102`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-new-103`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-new-201`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-new-202`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-203`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-301`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-302`,

  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-710`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-711`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-712`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-713`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-714`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-715`,

  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-721`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-722`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-723`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-724`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-725`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-726`,

  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-731`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-732`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-733`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-734`,

  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-741`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-742`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-743`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-744`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-751`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-752`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-753`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-754`,
  `${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/all-755`,
];

const Amenities_demos = [
  // { name: "la-key", icon: "la-key" },
  { name: "luggage-cart", icon: "la-luggage-cart" },
  { name: "shower", icon: "la-shower" },
  //  { name: "la-smoking", icon: "la-smoking" },
  //  { name: "la-snowflake", icon: "la-snowflake" },
  { name: "spa", icon: "la-spa" },
  // { name: "la-suitcase", icon: "la-suitcase" },
  // { name: "la-suitcase-rolling", icon: "la-suitcase-rolling" },
  //{ name: "la-swimmer", icon: "la-swimmer" },
  { name: "swimming-pool", icon: "la-swimming-pool" },
  { name: "tv", icon: "la-tv" },
  // { name: "la-umbrella-beach", icon: "la-umbrella-beach" },
  // { name: "la-utensils", icon: "la-utensils" },
  // { name: "la-wheelchair", icon: "la-wheelchair" },
  { name: "wifi", icon: "la-wifi" },
  // { name: "la-baby-carriage", icon: "la-baby-carriage" },
  // { name: "la-bath", icon: "la-bath" },
  //  { name: "la-bed", icon: "la-bed" },
  //  { name: "la-briefcase", icon: "la-briefcase" },
  { name: "car", icon: "la-car" },
  { name: "cocktail", icon: "la-cocktail" },
  { name: "coffee", icon: "la-coffee" },
  { name: "concierge-bell", icon: "la-concierge-bell" },
  // { name: "la-dice", icon: "la-dice" },
  // { name: "la-dumbbell", icon: "la-dumbbell" },
  { name: "hot-tub", icon: "la-hot-tub" },
  // { name: "la-infinity", icon: "la-infinity" },
];

export interface roomRateType {
  rmTpCode: string;
  rmTpRate: Number;
  rmTpAmt: Number;
}

const PageHomeMhotel: FC<PageHomeMhotelProps> = ({
  className = "",
  isPreviewMode,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenRoomType, setIsOpenRoomType] = useState(false);
  const [modalPhotos, setModalPhotos] = useState([]);
  const [amtRoomRate, setAmtRoomRate] = useState<roomRateType[]>([]);
  const [adultQty, setAdultQty] = useState(0);
  const [childQty, setChildQty] = useState(0);
  const [infantQty, setInfantQty] = useState(0);
  const [roomQty, setRoomQty] = useState(0);
  const [extraBedQty, setExtraBedQty] = useState(0);



  const qtyItems = [
    { roomType: 'STS', value: 0 },
    { roomType: 'STW', value: 0 },
    { roomType: 'DLX', value: 0 },
    { roomType: 'SUT', value: 0 },
    { roomType: 'COR', value: 0 }
  ];

  const adultQtys = [
    { roomType: 'STS', value: 1 },
    { roomType: 'STW', value: 1 },
    { roomType: 'DLX', value: 1 },
    { roomType: 'SUT', value: 1 },
    { roomType: 'COR', value: 1 }
  ];


  const roomsQtys = [
    { roomType: 'STS', value: 1 },
    { roomType: 'STW', value: 1 },
    { roomType: 'DLX', value: 1 },
    { roomType: 'SUT', value: 1 },
    { roomType: 'COR', value: 1 }
  ];

  const prices = [
    { roomType: 'STS', value: 900 },
    { roomType: 'STW', value: 950 },
    { roomType: 'DLX', value: 1100 },
    { roomType: 'SUT', value: 1500 },
    { roomType: 'COR', value: 1200 }
  ];

  const [adultQtyValues, setAdultQtyValues] = useState(adultQtys);
  const [childQtyValues, setChildQtyValues] = useState(qtyItems);
  const [infantQtyValues, setInfantQtyValues] = useState(qtyItems);
  const [roomQtyValues, setRoomQtyValues] = useState(roomsQtys);
  const [extraBedQtyValues, setExtraBedQtyValues] = useState(qtyItems);
  const [priceValues, setPriceValues] = useState(prices);


  const updateadultQtysValue = (_roomType: any, _value: any) => {
    let newValues = adultQtyValues.map(el => (
      el.roomType == _roomType ? { ...el, value: _value } : el
    ))
    setAdultQtyValues(newValues);
  };


  const getAdultQty = (_roomType: any) => {
    let qty = 0;
    let newValues = adultQtyValues.map(el => {
      if (el.roomType == _roomType) {
        qty = el.value
      }
    })
    return qty;
  };

  const calAmt = (_rmTpCode: any) => {
    amtRoomRate.map((itm: any) => {
      if (itm.rmTpCode == _rmTpCode) return {} as roomRateType
      else return itm;
    })


  }


  const updateChildQtysValue = (_roomType: any, _value: any) => {
    let newValues = childQtyValues.map(el => (
      el.roomType == _roomType ? { ...el, value: _value } : el
    ))
    setChildQtyValues(newValues);
  };


  const getChildQty = (_roomType: any) => {
    let qty = 0;
    let newValues = childQtyValues.map(el => {
      if (el.roomType == _roomType) {
        qty = el.value
      }
    })
    return qty;
  };


  const updateInfantQtysValue = (_roomType: any, _value: any) => {
    let newValues = infantQtyValues.map(el => (
      el.roomType == _roomType ? { ...el, value: _value } : el
    ))
    setInfantQtyValues(newValues);
  };


  const getInfantQty = (_roomType: any) => {
    let qty = 0;
    let newValues = infantQtyValues.map(el => {
      if (el.roomType == _roomType) {
        qty = el.value
      }
    })
    return qty;
  };


  const updateRoomQtysValue = (_roomType: any, _value: any) => {
    let newValues = roomQtyValues.map(el => (
      el.roomType == _roomType ? { ...el, value: _value } : el
    ))
    setRoomQtyValues(newValues);
  };


  const getRoomQty = (_roomType: any) => {
    let qty = 0;
    let newValues = roomQtyValues.map(el => {
      if (el.roomType == _roomType) {
        qty = el.value
      }
    })
    return qty;
  };

  const updateExtraBedQtysValue = (_roomType: any, _value: any) => {
    let newValues = extraBedQtyValues.map(el => (
      el.roomType == _roomType ? { ...el, value: _value } : el
    ))
    setExtraBedQtyValues(newValues);
  };


  const getExtraBedQty = (_roomType: any) => {
    let qty = 0;
    let newValues = extraBedQtyValues.map(el => {
      if (el.roomType == _roomType) {
        qty = el.value
      }
    })
    return qty;
  };


  const getPrice = (_roomType: any) => {
    let price = 0;
    let newValues = priceValues.map(el => {
      if (el.roomType == _roomType) {
        price = el.value
      }
    })
    return price;
  };

  const handleSubmitBooking = async (_roomType: any, _roomTypeName: any) => {
    let bookingData = {
      roomType: _roomType,
      roomTypeName: _roomTypeName,
      adultQty: getAdultQty(_roomType),
      childQty: getChildQty(_roomType),
      infantQty: getInfantQty(_roomType),
      roomQty: getRoomQty(_roomType),
      extraBedQty: getExtraBedQty(_roomType),
      price: getPrice(_roomType),
      roomPrice: getPrice(_roomType),
      startDate: booking.startDate,//moment(),
      endDate: booking.endDate//moment().add(2, "days"),

    }
    await booking.onSetData(bookingData);

    console.log(booking.bookingData)

    localStorage.setItem('bookingData', JSON.stringify(bookingData));

    // setTimeout(() => {
    //   history.push('/booking');
    // }, 500);

  }

  //const [vals, setVals] = useState(items);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: moment(),
    endDate: moment().add(4, "days"),
  });

  const booking = useBooking();
  const roomTypes = useRoomTypes();
  const modalRoomType = useModalRoomType();

  //const myRef = useRef<HTMLDivElement>(null);
  const myRef = React.createRef<HTMLDivElement>();

  //const executeScroll = () => myRef.current.focus({ preventScroll: false });

  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const windowSize = useWindowSize();

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };


  const handleAdultChange = (roomType: any, qty: any) => {
    updateadultQtysValue(roomType, qty)
  }

  const handleChildChange = (roomType: any, qty: any) => {
    updateChildQtysValue(roomType, qty)
  }

  const handleInfantChange = (roomType: any, qty: any) => {
    updateInfantQtysValue(roomType, qty)
  }

  const handleRoomChange = (roomType: any, qty: any) => {
    updateRoomQtysValue(roomType, qty)
  }

  const handleExtraBedChange = (roomType: any, qty: any) => {
    updateExtraBedQtysValue(roomType, qty)
  }

  const handleOpenRooomTypeModal = (index: number, modalPhotos: any) => {

    console.log('index', index)
    console.log('modalPhotos', modalPhotos)


    setModalPhotos(modalPhotos);
    setOpenFocusIndex(index);
    //setIsOpenRoomType(true);
    //modalRoomType.openModal(true)


    setTimeout(() => {
      //   //setIsOpenRoomType(true);
      modalRoomType.openModal(true);
    }, 500);

    // console.log('isOpenRoomType', isOpenRoomType)

  };

  const handleCloseModal = () => setIsOpen(false);
  const handleCloseRooomTypeModal = () => modalRoomType.openModal(false);
  //const handleCloseRooomTypeModal = () => { };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name="Wooden house" />
          <LikeSaveBtns />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          Beach House in Collingwood
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> Tokyo, Jappan</span>
          </span>
        </div>

        {/* 4 */}
        <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              Kevin Francis
            </span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              6 <span className="hidden sm:inline-block">guests</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              6 <span className="hidden sm:inline-block">beds</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              3 <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              2 <span className="hidden sm:inline-block">bedrooms</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">M Hotel</h2>
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div> */}
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            Welcome to M Hotel M Hotel is a 3 star hotel, a luxury and
            remarkable hotel,located on downtown and main attractive area at
            Dannok.Step into one of 153 inviting rooms and escape the stresses
            of the day with a wide range of amenities such as towels,
            television, internet access-wireless (complimentary), air
            conditioning,etc.
          </span>
          <br />
          <br />
          {/* <span>
            There is a private bathroom with bidet in all units, along with a
            hairdryer and free toiletries.
          </span>
          <br /> <br />
          <span>
            The Symphony 9 Tam Coc offers a terrace. Both a bicycle rental
            service and a car rental service are available at the accommodation,
            while cycling can be enjoyed nearby.
          </span> */}
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            About the property's amenities and services
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {Amenities_demos.filter((_, i) => i < 12).map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>

        {/* ----- */}
        {/* <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            View more 20 amenities
          </ButtonSecondary>
        </div> */}
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-6 space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderRoomTypeSection = (
    roomTypeName: any,
    roomTypeCode: any,
    imageurl: any,
    roomRate: any,
    extraBed: any,
    modalPhotos: any
  ) => {



    return (
      <div className="listingSection__wrap" ref={myRef}>
        <div>
          <h2 className="text-2xl font-semibold">{roomTypeName}</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            About the property's amenities and services
          </span>
        </div>
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div> */}
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-5 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          <ul className="mt-5 space-y-4">
            <li>
              <div
                className="cursor-pointer"
                onClick={() => handleOpenRooomTypeModal(0, modalPhotos)}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-4"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={modalPhotos[0] || ""}
                  prevImageHorizontal
                />
              </div>
            </li>

            <li>
              <div className="relative grid grid-cols-3 sm:grid-cols-1 gap-1 sm:gap-2">
                <div
                  className="hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 my-2 mx-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                  onClick={() => handleOpenRooomTypeModal(0, modalPhotos)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                    />
                  </svg>
                  <span className="ml-2 text-neutral-800 text-sm font-medium">
                    Show all photos
                  </span>
                </div>
              </div>
            </li>
            {/* <li>ฟรี ! Wi-Fi</li>
            <li>1 เตียง 6 ฟุต</li>
            <li>1 ห้องนอน</li>
            <li>1 ห้องน้ำ</li>
            <li>ทีวี</li>
            <li>น้ำอุ่น</li>
            <li>ผ้าเช็ดตัว</li>
            <li>น้ำดื่ม 2 ขวด</li> */}
          </ul>

          <ul className="mt-5 space-y-4">
            <li>ฟรี ! Wi-Fi</li>
            <li>1 เตียง 6 ฟุต</li>
            <li>1 ห้องนอน</li>
            <li>1 ห้องน้ำ</li>
            <li>ทีวี</li>
            <li>น้ำอุ่น</li>
            <li>ผ้าเช็ดตัว</li>
            <li>น้ำดื่ม 2 ขวด</li>
            {/* <li>
              <h5 className="text-xl ">สิทธิประโยชน์</h5>
            </li>
            <li>จองวันนี้จ่ายในอีก 3 วัน</li>
            <li>ฟรี Wi-Fi</li>
            <li>ยกเลิกการจองได้ฟรี</li>
            <li>ลด 30%</li>
            <li>ราคาสำหรับคนในประเทศ</li>
            <li>ราคาสิทธิประโยชน์ 6</li>
            <li>ราคาสิทธิประโยชน์ 7</li>
            <li>ราคาสิทธิประโยชน์ 8</li> */}
          </ul>

          <ul className="mt-5 space-y-4">
            <li>
              <h5 className="text-xl ">ผู้เข้าพัก</h5>
            </li>
            <div>
              <Label>ผู้ใหญ่</Label>
              <Select className="mt-1.5"
                onChange={(e) => { handleAdultChange(roomTypeCode, e.target.value) }}
                value={getAdultQty(roomTypeCode)}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Select>
            </div>
            <div>
              <Label>เด็ก</Label>
              <Select className="mt-1.5"
                onChange={(e) => { handleChildChange(roomTypeCode, e.target.value) }}
                value={getChildQty(roomTypeCode)}
              >
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Select>
            </div>
            <div>
              <Label>อายุ(ต่ำกว่า 12 พักฟรี)</Label>
              <Select className="mt-1.5"
                onChange={(e) => { handleInfantChange(roomTypeCode, e.target.value) }}
                value={getInfantQty(roomTypeCode)}
              >
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Select>
            </div>
          </ul>

          <ul className="mt-5 space-y-4">
            <li>
              <h5 className="text-xl">ราคาห้อง/คืน</h5>
            </li>
            {/* <li className=" mt-1.5">
              <h5 className="text-xl text-red-600">SALE! ลด 30% วันนี้</h5>
            </li>*/}
            <li className=" mt-1.5">
              <h5 className="text-xl text-red-600">{roomRate} บาท</h5>
            </li>

            <div>
              <Label>จำนวนห้อง</Label>
              <Select className="mt-2"
                onChange={(e) => { handleRoomChange(roomTypeCode, e.target.value) }}
                value={getRoomQty(roomTypeCode)}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Select>
            </div>
            <div>
              <Label>เพิ่มเตียงเสริม {extraBed} บาท</Label>
              <Select className="mt-1.5"
                onChange={(e) => { handleExtraBedChange(roomTypeCode, e.target.value) }}
                value={getExtraBedQty(roomTypeCode)}
              >
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
              </Select>
            </div>
          </ul>

          <ul className="mt-5 space-y-4">
            <li>
              <ButtonPrimary type="button"
                onClick={() => {
                  //history.push('/booking')
                  handleSubmitBooking(roomTypeCode, roomTypeName)


                }}
              >{`   จอง   `}</ButtonPrimary>
            </li>
            {/* <li>ราคายังไม่รวม ภาษีมูลค่าเพิ่ม</li> */}
            <li></li>
            <li className=" mt-1.5">
              {/* <h5 className="text-xl text-blue-600">3,600 บาท</h5> */}
            </li>
            <li></li>
          </ul>

          {/* {Amenities_demos.filter((_, i) => i < 12).map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))} */}
        </div>

        {/* ----- */}
        {/* <div className="w-14 border-b border-neutral-200"></div> */}
        {/* <div>
          <ButtonSecondary onClick={openModalAmenities}>
            View more 20 amenities
          </ButtonSecondary>
        </div> */}
        {/* {renderRoomType()} */}
      </div>
    );
  };

  const renderRoomType = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Room Type
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-6 space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Room Rates </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday - Thursday</span>
              <span>$199</span>
            </div>
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday - Thursday</span>
              <span>$199</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Friday - Sunday</span>
              <span>$219</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Rent by month</span>
              <span>-8.34 %</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Minimum number of nights</span>
              <span>1 night</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Max number of nights</span>
              <span>90 nights</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Availability</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        <div className="listingSection__wrap__DayPickerRangeController flow-root">
          <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
            <DayPickerRangeController
              startDate={selectedDate.startDate}
              endDate={selectedDate.endDate}
              onDatesChange={(date) => setSelectedDate(date)}
              focusedInput={focusedInputSectionCheckDate}
              onFocusChange={(focusedInput) =>
                setFocusedInputSectionCheckDate(focusedInput || "startDate")
              }
              initialVisibleMonth={null}
              numberOfMonths={windowSize.width < 1280 ? 1 : 2}
              daySize={getDaySize()}
              hideKeyboardShortcutsPanel={false}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              Kevin Francis
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
          accommodation, an outdoor swimming pool, a bar, a shared lounge, a
          garden and barbecue facilities...
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="##">See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Dannok, Songkhla, Thailand
          </span>
        </div>
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" /> */}

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <div className="rounded-xl overflow-hidden">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyDxJaU8bLdx7sSJ8fcRdhYS1pLk8Jdvnx0",
              }}
              defaultZoom={15}
              yesIWantToUseGoogleMapApiInternals
              defaultCenter={{
                lat: 6.5273184,
                lng: 100.4148013,
              }}
            >
              <LocationMarker lat={6.5273184} lng={100.4148013} />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know เงื่อนไขยกเลิกการพัก และการคืนเงิน</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy </h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Any Canncellation received within 7 days prior to arrival date will
            incur the first night charge. Failure to arrive at your hotel or
            property will be treated as a No-Show and no refund will be given
            (Property policy).
            <br />
            หากท่านยกเลิกการจองห้องพัก 7 วันก่อนวันเช็คอิน
            ท่านจะถูกเรียกเก็บเงินเท่ากับเข้าพักคืนแรก
            กรณีที่ท่านไม่เข้าเช็คอินตามที่โรงแรมกำหนด จะถือเป้นการไม่เข้าพัก
            และท่านไม่สามารถขอรับเงินคืนได้ (นโยบายโรงแรม)
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>02:00 pm</span>
              {/* <span>08:00 am - 12:00 am</span> */}
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>12:00 pm</span>
              {/* <span>02:00 pm - 04:00 pm</span> */}
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        {/* <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and
                environment green and clean by not littering, not using
                stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div> */}
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSection__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            $119
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /night
            </span>
          </span>
          <StartRating />
        </div>

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
            onChange={(date) => setSelectedDate(date)}
            numberOfMonths={1}
            fieldClassName="p-5"
            defaultValue={selectedDate}
            anchorDirection={windowSize.width > 1400 ? "left" : "right"}
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            fieldClassName="p-5"
            defaultValue={{
              guestAdults: 1,
              guestChildren: 2,
              guestInfants: 0,
            }}
          />
        </form>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>$119 x 3 night</span>
            <span>$357</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>$0</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>$199</span>
          </div>
        </div>

        {/* SUBMIT */}
        <ButtonPrimary>Reserve</ButtonPrimary>
      </div>
    );
  };

  const handleSarch = () => {
    let req = {
      startDate: booking.startDate == null ? moment() : booking.startDate,
      endDate: booking.endDate == null ? moment().add(4, "days") : booking.endDate
    }


    //booking.getAllBooking()
    roomTypes.getAll(req);
  }

  useEffect(() => {
    handleSarch();

  }, [])

  useEffect(() => {
    //booking.getAllBooking()
    //roomTypes.getAll();
    console.log('roomTypes', roomTypes?.datas)
    roomTypes?.datas.map((itm: any) => {
      //console.log('itm >> ', itm)
      //amtRoomRate.push({ rmTpCode: itm.rmTpCode, rmTpAmt: itm.rate.roomRate, rmTpRate: itm.itm.rate });
      //amtRoomRate.push({ rmTpCode: '1', rmTpAmt: 1, rmTpRate: 1 });
    })


    //amtRoomRate['STS':any].push({})
    //amtRoomRate.push({})

    console.log('amtRoomRate : ', amtRoomRate)


  }, [roomTypes?.datas])





  return (
    <div
      className={`nc-ListingStayDetailPage  ${className}`}
      data-nc-id="ListingStayDetailPage"
    >
      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={PHOTOS[0]}
                prevImageHorizontal
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                  }`}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item || ""}
                  prevImageHorizontal
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 1)}
                />
              </div>
            ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        {isOpen &&
          <ModalRoomTypePhotos
            imgs={PHOTOS}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={0}
          />}
        {modalRoomType.isOpenModal &&
          <ModalRoomTypePhotos
            imgs={modalPhotos}
            isOpen={modalRoomType.isOpenModal}
            onClose={handleCloseRooomTypeModal}
            initFocus={0}
          />}
      </>

      {/* MAIn */}
      <main className="container mt-5 flex ">
        {/* CONTENT */}
        <div className="w-full lg:w-full xl:w-full space-y-5 lg:space-y-5 ">
          {/* {renderSection1()} */}
          <SectionHero onSearch={handleSarch} />
          {renderSection2()}
          {renderSection3()}

          {roomTypes?.datas && roomTypes?.datas.map((itm: any) => {
            //  let _images!: string[]
            let _images: Array<string> = [];
            itm.images?.map((img: any) => {
              if (img?.imageUrl)
                _images.push(`${REACT_APP_ASE_IMAGE_API_URL}${img.imageUrl}`)
            });
            return (
              renderRoomTypeSection(
                itm.rmTpName,
                itm.rmTpCode,
                _images ? _images[0] : '',
                itm.rate?.roomRate,
                itm.rate?.extraBed,
                _images
                //PhotosStandardDouble
              ))
          }
          )}

          {/* {renderRoomTypeSection(
            "Standard Double",
            "https://booking-ui.devz.sbs/images/r1.jpg",
            900,
            300,
            PhotosStandardDouble
          )}
          {renderRoomTypeSection(
            "Standard Twin",
            "https://booking-ui.devz.sbs/images/r2.jpg",
            950,
            300,
            PhotosStandardTwin
          )}
          {renderRoomTypeSection(
            "Deluxe",
            "https://booking-ui.devz.sbs/images/r3.jpg",
            1100,
            300,
            PhotosDeluxe
          )}
          {renderRoomTypeSection(
            "Connect",
            "https://booking-ui.devz.sbs/images/r4.jpg",
            1500,
            300,
            PhotosConnect
          )}
          {renderRoomTypeSection(
            "Corner",
            "https://booking-ui.devz.sbs/images/r5.jpg",
            1200,
            300,
            PhotosCorner
          )} */}
          {/* {renderSection4()} */}
          {/* {renderSectionCheckIndate()} */}
          {/* {renderSection5()}
          {renderSection6()} */}

          {renderSection8()}
          <SectionGridCategoryBox />
          <SectionVideos />
          {renderSection7()}
        </div>

        {/* SIDEBAR */}
        {/* <div className="hidden lg:block flex-grow">
          <div className="sticky top-24">{renderSidebar()}</div>
        </div> */}
      </main>

      {/* STICKY FOOTER MOBILE */}
      {!isPreviewMode && (
        <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
          {/* <div className="container flex items-center justify-between">
            <span className="text-2xl font-semibold">
              $311
              <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                /night
              </span>
            </span>

            <ButtonPrimary href="##">Reserve</ButtonPrimary>
          </div> */}
        </div>
      )}

      {/* OTHER SECTION */}
      {!isPreviewMode && (
        <div className="container py-5 lg:py-5">
          {/* SECTION 1 */}
          {/* <div className="relative py-16">
            <BackgroundSection />
            <SectionSliderNewCategories
              heading="Explore by types of stays"
              subHeading="Explore houses based on 10 types of stays"
              categoryCardType="card5"
              itemPerRow={5}
              sliderStyle="style2"
            />
          </div> */}

          {/* SECTION */}
          {/* <SectionSubscribe2 className="pt-24 lg:pt-32" /> */}
        </div>
      )}
    </div>
  );
};

export default PageHomeMhotel;
