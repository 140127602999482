/* eslint-disable */
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper, apiBaseURL } from 'helpers';
import TokenService from "helpers/token.service";

export { useAuthen, authAtom, usersAtom };

const authAtom = atom({
    key: 'auth',
    default: JSON.parse(localStorage.getItem('user') || '{}')
});

const usersAtom = atom({
    key: 'users',
    default: null
});



function useAuthen() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();

    const setAuth = useSetRecoilState(authAtom);
    const authValue = useRecoilValue(authAtom);

    const setUsers = useSetRecoilState(usersAtom);
    const usersValue = useRecoilValue(usersAtom);

    return {
        login,
        logout,
        register,
        setAuth: setAuth,
        auth: authValue,
        userInfo: usersValue
    }

    function login(username: any, password: any) {
        return fetchWrapper.post(`${baseUrl}/accounts/login`, { email: username, password: password })
            .then((user: any) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user.data));
                setAuth(user.data);

                TokenService.setAccessToken(user?.data?.accessToken);
                TokenService.setRefreshToken(user?.data?.refreshToken);

                // get return url from location state or default to home page
                //const { from } = history.location.state || { from: { pathname: '/' } };
                //history.push(from);
                history.push('/');
            });
    }

    function register(reg: any) {
        return fetchWrapper.post(`${baseUrl}/accounts/register`, {
            email: reg.email,
            password: reg.password,
            confirmPassword: reg.confirmPassword,
            firstName: reg.firstName,
            lastName: reg.lastName
        })
            .then((response: any) => {
                //history.push('/');

                //console.log(response)

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('user', JSON.stringify(user.data));
                //setAuth(user.data);

                // get return url from location state or default to home page

                //const { from } = history.location.state || { from: { pathname: '/' } };
                //history.push(from);

                // setTimeout(() => {
                //     history.push(from);
                // }, 500);

            });
    }


    function logout() {
        // remove user from local storage, set auth state to null and redirect to login page
        localStorage.removeItem('user');
        setAuth(null);
        history.push('/');
    }

}