import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";

export interface PagePrivacyProps {
    className?: string;
}

const info = [
    {
        title: "🗺 ADDRESS",
        desc: "888 Moo 7 Samnukkam, Sadao , Songkhla, Thailand 90320",
    },
    {
        title: "💌 EMAIL",
        desc: "fo.mhotel@vhmthailand.com",
    },
    {
        title: "☎ PHONE",
        desc: "+66 74 557172",
    },
];

const renderSection8 = () => {
    return (
        <div className="listingSection__wrap">

            {/* CONTENT */}
            <div>
                <h4 className="text-lg font-semibold">1.นโยบายการคุ้มครองข้อมูลส่วนบุคคลสำหรับลูกค้าผู้ใช้บริการ</h4>
                <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                    บริษัท เจแอลที คอร์ปอเรท จำกัด (“บริษัท“) ตระหนักถึงความสำคัญของความเป็นส่วนตัวของลูกค้าผู้ใช้บริการ บริษัทจึงได้จัดทำนโยบายคุ้มครองข้อมูลส่วนบุคคลสำหรับลูกค้าผู้ใช้บริการฉบับนี้ เพื่อชี้แจงถึงแนวปฏิบัติเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล รวมถึงสิทธิต่าง ๆ ของเจ้าของข้อมูลส่วนบุคคล เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 ดังนี้
                </span>
                <h4 className="text-lg font-semibold">2.ข้อมูลส่วนบุคคลที่เก็บรวมรวม</h4>
                <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                    ข้อมูลส่วนบุคคลหมายถึงข้อมูลที่สามารถใช้เพื่อระบุตัวตนของเจ้าของข้อมูลที่เป็นลูกค้าผู้ใช้บริการได้ ไม่ว่าทางตรงหรือทางอ้อม โดยบริษัทอาจเก็บรวบรวมข้อมูลของลูกค้าผู้ใช้บริการ
                    ด้วยวิธีการ หรือแหล่งต่าง ๆ เช่น เก็บรวบรวมจากลูกค้าโดยตรงจากโปรแกรมระบบการจองห้องพัก เป็นต้น ประเภทของข้อมูลส่วนบุคคลที่เก็บรวบรวมมีดังต่อไปนี้
                    คำนำหน้าชื่อ ชื่อ สกุล อีเมล เบอร์โทรศัพท์ สัญชาติ
                </span>
                <h4 className="text-lg font-semibold">3.วัตถุประสงค์ของการเก็บข้อมูล</h4>
                <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                    เพื่อให้บริการแพลตฟอร์มการจองห้องพักของโรงแรม ตรงตามความต้องการของลูกค้าผู้ใช้บริการเป็นไปอย่างมีประสิทธิภาพ ตลอดจนการดำเนินงานของบริษัท บริษัทมีความจำเป็นต้องเก็บรวบรวม ใช้ เปิดเผย และ/หรือโอน ข้อมูลส่วนบุคคล ที่จำเป็นและกฎหมายอนุญาตเท่านั้น
                    ไปยังบุคคลที่เกี่ยวข้องของบริษัทได้แก่ พนักงานตอนรับ พนักงานบัญชี บริษัทในเครือ
                    พันธมิตรทางธุรกิจของบริษัท ผู้ให้บริการเซิร์ฟเวอร์ หากลูกค้าผู้ใช้บริการปฏิเสธไม่ให้ข้อมูลส่วนบุคคลแก่บริษัท อาจทำให้บริษัทไม่สามรถจัดทำบันทึกการจองห้องพักได้ และ/หรือให้บริการแก่ลูกค้าผู้ใช้บริการได้
                </span>
                <h4 className="text-lg font-semibold">4.ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล</h4>
                <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                    บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของลูกค้าผู้ใช้บริการเป็นระยะเวลาเท่าที่จำเป็นหรือตาม
                    ระยะเวลาที่กฎหมายกำหนด เพื่อวัตถุประสงค์ในกำรเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
                    ซึ่งได้ระบุไว้ในนโยบายคุ้มครองข้อมูลส่วนบุคคลนี้
                </span>
                <h4 className="text-lg font-semibold">5.กรณีที่ลูกค้าต้องการลบ หรือแก้ไขข้อมูลส่วนบุคคล</h4>
                <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                    ลูกค้าสามารถติดต่อลบ หรือแก้ไขข้อมูลส่วนบุคคลของลูกค้าได้ โดยติดต่อมาทางแผนกสารสนเทศ ของบริษัท
                </span>
                <h4 className="text-lg font-semibold">6.การเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล</h4>
                <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                    บริษัทขอสงวนสิทธิ์ที่จะทำการเปลี่ยนแปลงแก้ไขนโยบายคุ้มครองข้อมูลส่วนบุคคลนี้ ตามที่
                    เห็นสมควร รวมทั้งทบทวนมาตรการดังกล่าว เมื่อมีความจำเป็น หรือเมื่อเทคโนโลยี
                    เปลี่ยนแปลงไป  เพื่อให้มีประสิทธิภาพในการรักษาความปลอดภัยที่เหมาะสม ทั้งนี้ให้
                    เป็นไปตามมาตรฐานขั้นต่ำตามที่กฏหมายกำหนด
                </span>
                <h4 className="text-lg font-semibold">7.ช่องทางการติดต่อกับบริษัท</h4>
                <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                    กรณีมีข้อสงสัย หรือต้องการสอบถามรายยละเอียดเกี่ยวกับนโยบายคุ้มครองข้อมูลส่วนบุคคลสามารถติดต่อบริษัทผ่านช่องทางดังต่อไปนี้<br />
                    แผนก : ดูแลสารสนเทศ<br />
                    โทรศัพท์ : +66 7455 7172<br />
                    EMAIL : fo.mhotel@vhmthailand.com<br />
                    ช่องทางติดต่อทางไปรษณีย์<br />
                    บริษัท เจแอลที คอร์ปอเรท จำกัด<br />
                    888 หมู่ 7 ต.สำนักขาม อ.สะเดา จ.สงขลา 90320<br />
                    นโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ มีผลบังคับใช้ตั้งแต่ วันที่ 1 มิถุนายน 2565 เป็นต้นไป

                </span>

            </div>

            {/* CONTENT */}
            {/* <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and
                environment green and clean by not littering, not using
                stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div> */}
        </div>
    );
};

const PagePrivacy: FC<PagePrivacyProps> = ({ className = "" }) => {
    return (
        <div
            className={`nc-PagePrivacy overflow-hidden ${className}`}
            data-nc-id="PagePrivacy"
        >
            <Helmet>
                <title>Privacy || M Hotel</title>
            </Helmet>
            <div className="mb-24 lg:mb-32">
                <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    นโยบายคุ้มครองข้อมูลส่วนบุคคล
                </h2>
                <div className="container max-w-7xl mx-auto">
                    {renderSection8()}
                </div>
            </div>

            {/* OTHER SECTIONS */}
            {/* <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div> */}
        </div>
    );
};

export default PagePrivacy;
