import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import Select from "shared/Select/Select";
import { useBooking } from "hooks/useBooking";
import { useRoomTypes } from "hooks/useRoomTypes";
import { useSendMail } from "hooks/useSendMail";
import { useStore } from "react-redux";
import moment from "moment";
import { useForm } from "react-hook-form";


const { REACT_APP_ASE_IMAGE_API_URL } = process.env;
export interface PageBookingProps {
  className?: string;
}

export interface bookingProps {
  roomType: string;
  roomTypeName: string;
  adultQty: number;
  childQty: number;
  infantQty: number;
  roomQty: number;
  extraBedQty: number;
  price: number;
  startDate: Date;
  endDate: Date;
  fullName: string;
  email: string;
  confirmEmail: string;
  phone: string;
  placeName: string;
}


const info = [
  {
    title: "🗺 ADDRESS",
    desc: "888 Moo 7 Samnukkam, Sadao , Songkhla, Thailand 90320",
  },
  {
    title: "💌 EMAIL",
    desc: "nc.example@example.com",
  },
  {
    title: "☎ PHONE",
    desc: "+66 74 557172",
  },
];

const PageBooking: FC<PageBookingProps> = ({ className = "" }) => {
  const booking = useBooking();
  const roomtype = useRoomTypes();
  const sendmail = useSendMail();
  const [bookingData, setBookingData] = useState<bookingProps>();
  const [roomNight, setRoomNight] = useState(0);
  const [netPrice, setNetPrice] = useState<Number>(0);
  const [roomPrice, setRoomPrice] = useState<any>(0);
  const [queryCode, setQueryCode] = useState<String>('')

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [placeName, setPlaceName] = useState('');

  const { register, handleSubmit, setError, setValue, formState: { errors }, formState } = useForm();

  useEffect(() => {
    let bookingData = JSON.parse(localStorage.getItem('bookingData') || '{}');

    console.log('booking page >> ');
    console.log(bookingData);

    let n = moment(bookingData?.endDate).diff(moment(bookingData?.startDate), 'days');
    setRoomNight(n);
    setBookingData(bookingData);



    roomtype.getRates({
      startDate: moment(bookingData?.startDate).format('YYYY-MM-DD'),
      endDate: moment(bookingData?.endDate).format('YYYY-MM-DD'),
      roomTypeCode: bookingData?.roomType,
      roomQty: parseInt(bookingData?.roomQty),
      adultQty: parseInt(bookingData?.adultQty),
      childQty: parseInt(bookingData?.childQty),
      extraBedQty: parseInt(bookingData?.extraBedQty)
    });


    // get roomtype rates
  }, [])


  useEffect(() => {
    console.log('room rates >>> : ');
    console.log(roomtype.rates)

    setRoomNight(1);
    setRoomNight(roomtype?.rates?.dayRates?.length);
    setNetPrice(roomtype?.rates?.netAmmt);
    //setRoomPrice(roomtype?.rates?.dayRates?.RoomRate);
    setQueryCode(roomtype?.rates?.queryCode);


    roomtype?.rates?.dayRates?.map((rate: any, idx: any) => {
      if (idx == 0) setRoomPrice(rate.roomRate)
    });

  }, [roomtype.rates])



  return (
    <div
      className={`nc-PageBooking overflow-hidden ${className}`}
      data-nc-id="PageBooking"
    >
      <Helmet>
        <title>Booking || M Hotel</title>
      </Helmet>

      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Booking
        </h2>


        <form
          // className="grid grid-cols-1 gap-6 listingSection__wrap"
          className="listingSection__wrap"
          action="#"
          method="post"
          onSubmit={handleSubmit(async (data) => {
            console.log('submit data : ', data)

            let _bookingData = JSON.parse(localStorage.getItem('bookingData') || '{}');

            console.log('booking page >> ');
            console.log(bookingData);

            let n = moment(bookingData?.endDate).diff(moment(bookingData?.startDate), 'days');
            setRoomNight(n);


            _bookingData.fullName = data.fullName;
            _bookingData.email = data.email;
            _bookingData.confirmEmail = data.confirmEmail;
            _bookingData.phone = data.phone;
            _bookingData.placeName = data.placeName;
            _bookingData.price = netPrice;
            _bookingData.roomPrice = roomPrice;
            _bookingData.nightQty = n;
            _bookingData.queryCode = queryCode;


            const bookingresp = await booking.onBooking(_bookingData);
            _bookingData.bookingNo = bookingresp?.bookingNo;
            //console.log('bookingresp', bookingresp)
            sendmail.onSendMail(_bookingData);

            //console.log('booking data :: >> ')
            //console.log(_bookingData)
          })}
        >
          <div className=" container grid grid-cols-1 sm:grid-cols-2">
            <div className=" grid-rows-2">
              <div className=" ">
                {/* <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 rounded "> */}
                <div className="max-w space-y-10">

                  <h2 className="text-2xl font-semibold">
                    กรุณากรอกข้อมูลของท่าน
                  </h2>
                  <label className="block">
                    <Label>ชื่อ - นามสกุล</Label>

                    <Input
                      // placeholder="Example Doe"
                      {...register("fullName")}
                      type="text"
                      className="mt-1"
                    />
                  </label>
                  <label className="block">
                    <Label>อีเมลล์</Label>

                    <Input
                      type="email"
                      {...register("email")}
                      //value={email}
                      //onChange={(e) => setEmail(e.target.value)}
                      // placeholder="example@example.com"
                      className="mt-1"
                    />
                  </label>
                  <label className="block">
                    <Label>ยืนยันอีเมลล์อีกครั้ง</Label>

                    <Input
                      type="email"
                      {...register("confirmEmail")}
                      //value={confirmEmail}
                      //onChange={(e) => setConfirmEmail(e.target.value)}
                      // placeholder="@example.com"
                      className="mt-1"
                    />
                    {/* <Textarea className="mt-1" rows={6} /> */}
                  </label>
                  <label className="block">
                    <Label>หมายเลขโทรศัพท์ (เว้นได้)</Label>

                    <Input
                      type="text"
                      {...register("phone")}
                      //value={phone}
                      //onChange={(e) => setPhone(e.target.value)}
                      // placeholder="example@example.com"
                      className="mt-1"
                    />
                  </label>
                  <label className="block">
                    <Label>ประเทศ/เขตปกครองที่พักอาศัย</Label>

                    {/* <Select className="mt-1.5"
                      {...register("placeName")}
                    >
                      <option value="1">ประเทศไทย</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </Select> */}

                    <select {...register("placeName")}
                      className={`nc-Select mt-1.5 block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
                    >
                      <option value="1">ประเทศไทย</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  </label>
                  <label className="block">
                    <input type="checkbox"
                      {...register("IsSomeoneElse")}
                    ></input>
                    <Label className="m-3">
                      ถ้าจองให้คนอื่นเข้าพัก กรุณาทำเครื่องหมายในช่องนี้
                    </Label>
                  </label>
                  {/* <div>
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div> */}

                </div>
                {/* <div>
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div> */}
                {/* </div> */}
              </div>

              <div className="listingSection__wrap max-w-7xl mx-auto mt-3 ">
                {/* <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 rounded "> */}
                <div className="max-w">
                  {/* <p>
                    ท่านรองรับ
                    <a
                      className="text-blue-600 underline underline-offset-1 "
                      href=""
                      target=""
                    >
                      ข้อกำหนดการใช้งาน
                    </a>
                    และ
                    <a
                      className="text-blue-600 underline underline-offset-1"
                      href=""
                      target=""
                    >
                      นโยบายความเป็นส่วนตัว
                    </a>
                    ของอโกด้าเมื่อดำเนินการต่อ
                  </p>
                  <br />
                  <p className="grid justify-items-end text-red-500">
                    ห้องสุดท้ายของเราในราคานี้สำหรับวันเข้าพักที่เลือก-รีบจองก่อนใครได้ไป
                  </p>
                  <br /> */}
                  <div className="grid justify-items-end">
                    <ButtonPrimary
                      type="submit"

                    // onClick={async () => {

                    //   let _bookingData = JSON.parse(localStorage.getItem('bookingData') || '{}');

                    //   console.log('booking page >> ');
                    //   console.log(bookingData);

                    //   let n = moment(bookingData?.endDate).diff(moment(bookingData?.startDate), 'days');
                    //   setRoomNight(n);


                    //   _bookingData.fullName = fullName;
                    //   _bookingData.email = email;
                    //   _bookingData.confirmEmail = confirmEmail;
                    //   _bookingData.phone = phone;
                    //   _bookingData.placeName = placeName;

                    //   const bookingresp = await booking.onBooking(_bookingData);
                    //   sendmail.onSendMail(_bookingData);

                    //   console.log('booking click !!! >> ')
                    //   console.log(_bookingData)

                    // }}
                    >
                      ต่อไป ขั้นตอนสุดท้าย
                    </ButtonPrimary>
                  </div>
                  <br />
                  <p className="grid justify-items-end text-green-700">
                    (กรอกข้อมูลการชำระเงินในหน้าต่อไป)
                  </p>
                </div>
                {/* </div> */}
              </div>
            </div>

            <div className=" grid grid-rows-2 sm:grid-cols-1">
              <div className="ml-5 ">
                {/* <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 rounded "> */}
                <div className="max-w-lg listingSection__wrap">
                  <span className="bg-blue-500 font-semibold text-white text-center rounded w-40">
                    ห้องราคาถูกที่สุด
                  </span>

                  {/* <div className="flex justify-between">
                    <span> {bookingData && (moment(bookingData?.startDate).format('DD-MM-YYYY'))} ถึง  {bookingData && moment(bookingData?.endDate).format('DD-MM-YYYY')}</span>
                    <span>{bookingData && roomNight} คืน</span>
                  </div> */}

                  <span className="pb-1">
                    {bookingData && bookingData.roomTypeName}
                  </span>
                  <span className="border-b-2 pb-3">
                    <span> {bookingData && (moment(bookingData?.startDate).format('DD-MM-YYYY'))} ถึง  {bookingData && moment(bookingData?.endDate).format('DD-MM-YYYY')}</span>
                    <span> จำนวน {bookingData && roomNight} คืน </span>
                  </span>


                  {roomtype?.rates?.dayRates && roomtype?.rates?.dayRates?.map((rate: any, idx: any) => (
                    <div className="flex justify-between">
                      <span> {idx + 1}) {bookingData && (moment(rate.bDate).format('DD-MM-YYYY'))} </span>
                      <span> {rate.netAmt.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} บาท</span>
                    </div>
                  ))

                  }


                  <div className=" border-b-2 grid grid-cols-1 sm:grid-cols-2">
                    <img
                      className="w-64 mb-3 "
                      src={`${REACT_APP_ASE_IMAGE_API_URL}/image-store/image/roomtype1-1`}
                    ></img>
                    <ul className="ml-3">
                      <i className=" las la-bed text-lg"></i>
                      <li>ห้องพัก {bookingData && (bookingData?.roomQty)} ห้อง ผู้ใหญ่ {bookingData && (bookingData?.adultQty)} คน</li>
                      {(bookingData && bookingData?.childQty > 0) && <>
                        <i className=" las la-bed text-lg"></i>
                        <li>เด็ก {bookingData?.childQty} คน</li></>}
                    </ul>
                  </div>
                  <p className="text-blue-600 underline underline-offset-1">
                    ขอรับเงินคืนไม่ได้
                  </p>
                </div>
              </div>

              <div className="max-w-3xl ml-5 mt-3">
                <div className="max-w-lg listingSection__wrap">
                  <div className="flex justify-between ">
                    <h3 className="text-lg font-semibold">ราคาที่จ่าย</h3>
                    <span>{roomtype?.rates && roomtype?.rates?.netAmmt?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
                  </div>
                  <p>รวม: ภาษี 7% </p>
                  {/* <p className="bg-green-500 font-semibold">
                    ราคาดีที่สุด! ที่ไหนถูกกว่าเราลดให้เท่ากันไปเลย
                  </p>
                  <p>ดีอะไรอย่างนี้ จองที่พักนี้ได้ส่วนลดตั้ง ฿1,374.59</p> */}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div >

      {/* OTHER SECTIONS */}
      {/* <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div> */}
    </div >
  );
};

export default PageBooking;
