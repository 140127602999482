/* eslint-disable */
import { useRecoilState } from 'recoil';

import { history } from './history';
import { authAtom } from 'hooks/useAuthen';
import instance from './api';

const { REACT_APP_API_URL, REACT_APP_API_URL_STAGING, REACT_APP_API_URL_PROD, REACT_APP_ENV, REACT_APP_API_URL_DEV,
    REACT_APP_BASE_URL, REACT_APP_BASE_URL_DEV, REACT_APP_BASE_URL_STAGING, REACT_APP_BASE_URL_PROD
} = process.env;

const apiBaseURL = REACT_APP_ENV == "production" ? REACT_APP_API_URL_PROD : (REACT_APP_ENV == "staging" ? REACT_APP_API_URL_STAGING : (REACT_APP_ENV == "development" ? REACT_APP_API_URL_DEV : REACT_APP_API_URL));
const baseURL = REACT_APP_ENV == "production" ? REACT_APP_BASE_URL_PROD : (REACT_APP_ENV == "staging" ? REACT_APP_BASE_URL_STAGING : (REACT_APP_ENV == "development" ? REACT_APP_BASE_URL_DEV : REACT_APP_BASE_URL));


export { useFetchWrapper, apiBaseURL, baseURL };


function useFetchWrapper() {
    const [auth, setAuth] = useRecoilState(authAtom);
    return instance;
}