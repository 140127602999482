import React, { FC } from "react";
import { DEMO_EXPERIENCES_LISTINGS } from "data/accommodation-listings";
import { ExperiencesDataType, StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import AccommodationCard from "components/AccommodationCard/AccommodationCard";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
  handleOnClick: () => void;
  setModalPhotos: (photos: any) => void;
}

const DEMO_DATA: ExperiencesDataType[] = DEMO_EXPERIENCES_LISTINGS.filter(
  (_, i) => i < 8
);

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = DEMO_DATA,
  handleOnClick = () => { handleOnClick() },
  setModalPhotos = (photos) => { setModalPhotos(photos) }
}) => {
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      {/* <Heading2
        heading="Experiences in Tokyo"
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            233 experiences
            <span className="mx-2">·</span>
            Aug 12 - 18
            <span className="mx-2">·</span>2 Guests
          </span>
        }
      /> */}

      {/* <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div> */}
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {data.map((stay) => (
          <AccommodationCard key={stay.id} data={stay} handleOnClick={handleOnClick} setModalPhotos={setModalPhotos} />
        ))}
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div> */}
    </div>
  );
};

export default SectionGridFilterCard;
